<template>
  <div class="bottomList">
    <div class="bot_business" :class="{active: activeFoot === 0}" @click="btnMemuJumpClick(1)">
      <p><i class="el-icon-s-home"></i></p>
      <span>首页</span>
    </div>
    <div class="bot_business" :class="{active: activeFoot === 1}" @click="btnMemuJumpClick(2)" v-if="isShowCarList">
      <p><i class="el-icon-goods"></i></p>
      <span>全部商品</span>
    </div>
    <div class="bot_business" :class="{active: activeFoot === 2}" @click="btnMemuJumpClick(3)" v-if="isShowCarList">
<!--      <el-badge :value="carlistCount" class="item">-->
        <p><i class="el-icon-shopping-cart-2"></i></p>
        <span>购物车</span>
<!--      </el-badge>-->
    </div>
    <div class="bot_business" :class="{active: activeFoot === 3}" @click="btnMemuJumpClick(4)">
      <p><i class="el-icon-user"></i></p>
      <span>我的</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeFoot: 0,
      carlistCount: 0,
      isShowCarList: true,  // 判断当前是否显示商城
    }
  },
  mounted() {
    this.checkFlag();
    this.checkRoute();
    // this.getCarList();
  },
  methods: {
    // 首页跳转事件
    btnMemuJumpClick(type) {
      let jumpName = (type === 1) ? "Home" : (type === 2) ? "menu" : (type === 3) ? "shopcart" : "mine";
      if (this.$route.name !== jumpName) {
        // 判断是否属于当前页面，如果不是，则做出跳转行为
        this.$router.replace({ name: jumpName });
      } else {
        // 否则原地刷新页面
        this.$router.go(0);
      }
    },
    // 检查跳转判断
    checkRoute() {
      let routeNow = this.$route.name;
      if (routeNow === "Home") {
        this.activeFoot = 0;
      } else if (routeNow === "menu") {
        this.activeFoot = 1;
      } else if (routeNow === "shopcart") {
        this.activeFoot = 2;
      } else if (routeNow === "mine") {
        this.activeFoot = 3;
      } else {
        this.activeFoot = null;
      }
    },
    // 检查是否商城接口信息
    checkFlag() {
      this.isShowCarList = (JSON.parse(this.$cookies.get("isCarList")) === 1)
    },
  }
}
</script>

