<template>
  <div class="myPage">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="header_title">佛山市灏海餐饮管理有限公司</div>
        </div>
      </el-header>
      <el-main>
        <div class="listTable">
          <div class="tableSwiper">
<!--            <img src="../assets/banner1.jpg" alt="首页-版图">-->
          </div>
          <div class="indIconTable">
            <div class="MenuVal" @click="zixunJump(1)">
              <img src="../assets/intro.png" alt="">
              <p>公司介绍</p>
            </div>
            <div class="MenuVal" @click="zixunJump(4)">
              <img src="../assets/announce.png" alt="">
              <p>通知公告</p>
            </div>
            <div class="MenuVal" @click="zixunJump(5)">
              <img src="../assets/contact.png" alt="">
              <p>联系我们</p>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <footer-part />
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import footerPart from "@/components/footer";
export default {
  data() {
    return {
      isLogin: true,
      popstateLock: 0, //是否监听物理返回键
      isShowManage: true,
      isShowValetWorship: true,
      isShowAppoint: true,
      isShowBusiness: true,
      employerShow: false,
    }
  },
  components: {
    footerPart
  },
  mounted() {
    this.checkLogin();
    this.checkShow();
    this.addPopstate(); //在导航栏挂载的时候，对物理返回键进行监听
  },
  watch: {
    popstateLock(val) {
      //在父组件调用方法改变lock的值的时候控制路由的监听来避免preview的close路由调用
      if (val) {
        this.addPopstate()
      } else {
        this.removePopstate()
      }
    }
  },
  destroyed() {
    this.removePopstate()
  },
  methods: {
    addPopstate() {
      //判断是否H5路由
      if (window.history && window.history.pushState) {
        // 往历史记录里面添加一条新的当前页面的url
        history.pushState(null, null, document.URL);
        // 给 popstate 绑定一个方法 监听页面刷新，并绑定getBack()来控制
        window.addEventListener('popstate', this.getBack, false);//false阻止默认事件
      }
    },
    removePopstate() {
      //解除监听
      window.removeEventListener('popstate', this.getBack, false);//false阻止默认事件
    },
    //指挥路由
    getBack() {
      //判断物理返回键是否监听
      if (!this.popstateLock) {
        window.history.pushState(null, null, '#')
      }
    },
    // 检查登录
    checkLogin() {
      let showToken = this.$cookies.get("shopToken");
      this.isLogin = (showToken)
      if (!showToken) {
        sessionStorage.clear();
        this.$cookies.remove("shopToken");
        this.$cookies.remove("isRead");
      }
    },
    checkShow() {
      // 检查显示的
      console.log(JSON.parse(this.$cookies.get("isValetWorshipList")))
      this.isShowManage = (JSON.parse(this.$cookies.get("isManageList")) === 1);
      this.isShowValetWorship = (JSON.parse(this.$cookies.get("isValetWorshipList")) === 1);
      this.isShowAppoint = (JSON.parse(this.$cookies.get("isAppointList")) === 1);
      this.isShowBusiness = (JSON.parse(this.$cookies.get("isBusinessAppoint")) === 1);
      if (this.$store.state && this.$store.state.employeeId) {
        this.employerShow = true;
      } else {
        this.employerShow = false;
      }
    },
    // 资讯信息
    zixunJump(num) {
      if (num === 1) {  // 跳转墓园介绍
        this.$router.replace({ name: "company"});
      } else if (num === 2) {  // 跳转到购买流程
        this.$router.replace({ name: "process"});
      } else if (num === 3) {  // 跳转到服务项目
        this.$router.replace({ name: "service"});
      } else if (num === 4) {  // 跳转到通知公告
        this.$router.replace({ name: "announce"});
      } else if (num === 5) {  // 跳转到联系我们
        this.$router.replace({ name: "contactUs"});
      }
    },
    // 我的订单跳转事件
    jumpOrder(num){
      if (num === 1) {  // 跳转到代客祭拜
        this.$router.replace({ name: "valetworship"});
      } else if (num === 2) {  // 跳转到管理费续交
        this.$router.replace({ name: "manager"});
      } else if (num === 3) {  // 跳转到业务预约
        this.$router.replace({ name: "appointment"});
      } else if (num === 4) {  // 跳转到预约祭拜
        this.$router.replace({ name: "appointWorship"});
      } else if (num === 5) {  // 跳转到预约祭拜核验
        this.$router.replace({ name: "checkCode"});
      } else if (num === 6) {  // 跳转到代缴管理费
        this.$router.replace({ name: "priceOther"});
      } else if (num === 7) {  // 跳转到代缴管理费
        this.$router.replace({ name: "safeCheck"});
      }
    },
  },
}
</script>

<style scoped lang="scss">
.listTable {
  position: relative;
  background-color: #fff;
  width: 100%;
  .tableSwiper {
    position: relative;
    height: 35vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/banner1.jpg") center no-repeat;
    background-size: cover;
  }
  .indIconTable {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .MenuVal {
      flex: 0 0 calc(100% / 3 - 1px);
      position: relative;
      padding: 1rem 0;
      border-right: 1px #efefef solid;
      border-bottom: 1px #efefef solid;
      &:nth-of-type(3n) {
        border-right: none;
        flex: 0 0 calc(100% / 3);
      }
      img {
        max-width: 100%;
        width: 40px;
        margin: 10px auto;
        display: block;
      }
      p {
        font-size: 1rem;
        text-align: center;
        color: #333;
        letter-spacing: 0.5px;
      }
    }
  }
  .indMenu {
    position: relative;
    padding: 0;
    width: 100%;
    overflow: hidden;
    .menuTil {
      background-color: #dcdcdc;
      padding: 8px 10px;
      font-size: 15px;
      font-weight: 600;
    }
    .MenuPart {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .MenuVal {
        flex: 0 0 33.33333%;
        position: relative;
        margin: 0 0 15px;
        img {
          max-width: 100%;
          width: 40px;
          margin: 10px auto;
          display: block;
        }
        p {
          font-size: 1rem;
          text-align: center;
          color: #333;
          letter-spacing: 0.5px;
        }
      }
    }
  }

}
</style>
